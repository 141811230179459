// THIS IS AN AUTOGENERATED FILE. REGENERATE USING THE TOKENS2CODE FIGMA PLUGIN

import type { VariablesCollection } from '../variables';
import { coreTokensSize, coreTokensSpace } from './coreTokens';
import {
  semanticTokensLightColor,
  semanticTokensDarkColor,
  semanticTokensSize,
  semanticTokensRadius,
  semanticTokensSpace,
} from './semanticTokens';

export const componentTokensLightColor = {
  'alert/color/attention/alert-bg-attention': semanticTokensLightColor['background/surface-inverse'],
  'alert/color/attention/alert-border-attention': semanticTokensLightColor['background/transparent'],
  'alert/color/attention/alert-fg-attention': semanticTokensLightColor['foreground/surface-inverse'],
  'alert/color/attention/alert-fg-attention-subdued': semanticTokensLightColor['foreground/surface-inverse-subdued'],
  'alert/color/attention/alert-icon-attention': semanticTokensLightColor['icon/surface'],
  'alert/color/attention/alert-text-attention': semanticTokensLightColor['text/surface'],
  'alert/color/danger/alert-bg-danger': semanticTokensLightColor['background/danger'],
  'alert/color/danger/alert-border-danger': semanticTokensLightColor['background/transparent'],
  'alert/color/danger/alert-fg-danger': semanticTokensLightColor['foreground/danger-default'],
  'alert/color/danger/alert-fg-danger-subdued': semanticTokensLightColor['foreground/danger-subdued'],
  'alert/color/danger/alert-icon-danger': semanticTokensLightColor['icon/danger'],
  'alert/color/danger/alert-text-danger': semanticTokensLightColor['text/danger'],
  'alert/color/info/alert-bg-info': semanticTokensLightColor['background/info'],
  'alert/color/info/alert-border-info': semanticTokensLightColor['background/transparent'],
  'alert/color/info/alert-fg-info': semanticTokensLightColor['foreground/info-default'],
  'alert/color/info/alert-fg-info-subdued': semanticTokensLightColor['foreground/info-subdued'],
  'alert/color/info/alert-icon-info': semanticTokensLightColor['icon/info'],
  'alert/color/info/alert-text-info': semanticTokensLightColor['text/info'],
  'alert/color/neutral/alert-bg-neutral': semanticTokensLightColor['background/neutral'],
  'alert/color/neutral/alert-border-neutral': semanticTokensLightColor['background/transparent'],
  'alert/color/neutral/alert-fg-neutral': semanticTokensLightColor['foreground/neutral'],
  'alert/color/neutral/alert-fg-neutral-subdued': semanticTokensLightColor['foreground/neutral'],
  'alert/color/neutral/alert-icon-neutral': semanticTokensLightColor['foreground/neutral'],
  'alert/color/neutral/alert-text-neutral': semanticTokensLightColor['foreground/neutral'],
  'alert/color/success/alert-bg-success': semanticTokensLightColor['background/success'],
  'alert/color/success/alert-border-success': semanticTokensLightColor['background/transparent'],
  'alert/color/success/alert-fg-subdued': semanticTokensLightColor['foreground/success-subdued'],
  'alert/color/success/alert-fg-success': semanticTokensLightColor['foreground/success-default'],
  'alert/color/success/alert-icon-success': semanticTokensLightColor['icon/success'],
  'alert/color/success/alert-text-success': semanticTokensLightColor['text/success'],
  'alert/color/warning/alert-bg-warning': semanticTokensLightColor['background/warning'],
  'alert/color/warning/alert-border-warning': semanticTokensLightColor['background/transparent'],
  'alert/color/warning/alert-fg-warning': semanticTokensLightColor['foreground/warning-default'],
  'alert/color/warning/alert-fg-warning-subdued': semanticTokensLightColor['foreground/warning-subdued'],
  'alert/color/warning/alert-icon-warning': semanticTokensLightColor['icon/warning'],
  'alert/color/warning/alert-text-warning': semanticTokensLightColor['text/warning'],
  'badge/color/alpha/badge-bg-alpha': semanticTokensLightColor['chart-background-subdued/alpha'],
  'badge/color/alpha/badge-fg-alpha': semanticTokensLightColor['chart-foreground-subdued/alpha'],
  'badge/color/attention/badge-bg-attention': semanticTokensLightColor['background/surface-inverse'],
  'badge/color/attention/badge-fg-attention': semanticTokensLightColor['foreground/surface-inverse'],
  'badge/color/beta/badge-bg-beta': semanticTokensLightColor['chart-background-subdued/beta'],
  'badge/color/beta/badge-fg-beta': semanticTokensLightColor['chart-foreground-subdued/beta'],
  'badge/color/danger/badge-bg-danger': semanticTokensLightColor['background/danger'],
  'badge/color/danger/badge-fg-danger': semanticTokensLightColor['foreground/danger-default'],
  'badge/color/delta/badge-bg-delta': semanticTokensLightColor['chart-background-subdued/delta'],
  'badge/color/delta/badge-fg-delta': semanticTokensLightColor['chart-foreground-subdued/delta'],
  'badge/color/epsilon/badge-bg-epsilon': semanticTokensLightColor['chart-background-subdued/epsilon'],
  'badge/color/epsilon/badge-fg-epsilon': semanticTokensLightColor['chart-foreground-subdued/epsilon'],
  'badge/color/eta/badge-bg-eta': semanticTokensLightColor['chart-background-subdued/eta'],
  'badge/color/eta/badge-fg-eta': semanticTokensLightColor['chart-foreground-subdued/eta'],
  'badge/color/gamma/badge-bg-gamma': semanticTokensLightColor['chart-background-subdued/gamma'],
  'badge/color/gamma/badge-fg-gamma': semanticTokensLightColor['chart-foreground-subdued/gamma'],
  'badge/color/info/badge-bg-info': semanticTokensLightColor['background/info'],
  'badge/color/info/badge-fg-info': semanticTokensLightColor['foreground/info-default'],
  'badge/color/iota/badge-bg-iota': semanticTokensLightColor['chart-background-subdued/iota'],
  'badge/color/iota/badge-fg-iota': semanticTokensLightColor['chart-foreground-subdued/iota'],
  'badge/color/neutral/badge-bg-neutral': semanticTokensLightColor['background/neutral'],
  'badge/color/neutral/badge-fg-neutral': semanticTokensLightColor['foreground/neutral'],
  'badge/color/success/badge-bg-success': semanticTokensLightColor['background/success'],
  'badge/color/success/badge-fg-success': semanticTokensLightColor['foreground/success-default'],
  'badge/color/theta/badge-bg-theta': semanticTokensLightColor['chart-background-subdued/theta'],
  'badge/color/theta/badge-fg-theta': semanticTokensLightColor['chart-foreground-subdued/theta'],
  'badge/color/warning/badge-bg-warning': semanticTokensLightColor['background/warning'],
  'badge/color/warning/badge-fg-warning': semanticTokensLightColor['foreground/warning-default'],
  'badge/color/zeta/badge-bg-zeta': semanticTokensLightColor['chart-background-subdued/zeta'],
  'badge/color/zeta/badge-fg-zeta': semanticTokensLightColor['chart-foreground-subdued/zeta'],
  'button/color/button-danger-bg-default': semanticTokensLightColor['background/danger'],
  'button/color/button-danger-bg-hover': semanticTokensLightColor['background/danger-hover'],
  'button/color/button-danger-bg-press': semanticTokensLightColor['background/danger-press'],
  'button/color/button-danger-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-danger-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-danger-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-danger-fg': semanticTokensLightColor['foreground/danger-default'],
  'button/color/button-danger-text': semanticTokensLightColor['text/danger'],
  'button/color/button-danger-text-hover': semanticTokensLightColor['text/danger-hover'],
  'button/color/button-danger-text-press': semanticTokensLightColor['text/danger-press'],
  'button/color/button-disabled-bg': semanticTokensLightColor['background/disabled'],
  'button/color/button-disabled-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-disabled-fg': semanticTokensLightColor['foreground/disabled'],
  'button/color/button-focus-border': semanticTokensLightColor['other/focus'],
  'button/color/button-inverse-bg-default': semanticTokensLightColor['other/white-alpha-50'],
  'button/color/button-inverse-bg-hover': semanticTokensLightColor['other/white-alpha-60'],
  'button/color/button-inverse-bg-press': semanticTokensLightColor['other/white-alpha-50'],
  'button/color/button-inverse-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-inverse-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-inverse-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-inverse-fg': semanticTokensLightColor['foreground/surface-default'],
  'button/color/button-outlined-danger-bg': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-danger-bg-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-danger-bg-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-danger-border': semanticTokensLightColor['border/danger'],
  'button/color/button-outlined-danger-border-hover': semanticTokensLightColor['border/danger-hover'],
  'button/color/button-outlined-danger-border-press': semanticTokensLightColor['border/danger-press'],
  'button/color/button-outlined-disabled-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-primary-bg': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-primary-bg-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-primary-bg-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-primary-border': semanticTokensLightColor['border/primary'],
  'button/color/button-outlined-primary-border-hover': semanticTokensLightColor['border/primary-hover'],
  'button/color/button-outlined-primary-border-press': semanticTokensLightColor['border/primary-press'],
  'button/color/button-outlined-secondary-bg': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-secondary-bg-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-secondary-bg-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-secondary-border': semanticTokensLightColor['border/secondary'],
  'button/color/button-outlined-secondary-border-hover': semanticTokensLightColor['border/secondary-hover'],
  'button/color/button-outlined-secondary-border-press': semanticTokensLightColor['border/secondary-press'],
  'button/color/button-outlined-tertiary-bg': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-tertiary-bg-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-tertiary-bg-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-outlined-tertiary-border': semanticTokensLightColor['background/tertiary-default'],
  'button/color/button-outlined-tertiary-border-hover': semanticTokensLightColor['background/tertiary-hover'],
  'button/color/button-outlined-tertiary-border-press': semanticTokensLightColor['background/tertiary-press'],
  'button/color/button-primary-bg-default': semanticTokensLightColor['background/primary-default'],
  'button/color/button-primary-bg-hover': semanticTokensLightColor['background/primary-hover'],
  'button/color/button-primary-bg-press': semanticTokensLightColor['background/primary-press'],
  'button/color/button-primary-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-primary-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-primary-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-primary-fg': semanticTokensLightColor['foreground/primary-default'],
  'button/color/button-primary-text': semanticTokensLightColor['text/primary'],
  'button/color/button-primary-text-hover': semanticTokensLightColor['text/primary-hover'],
  'button/color/button-primary-text-press': semanticTokensLightColor['text/primary-press'],
  'button/color/button-secondary-bg-default': semanticTokensLightColor['background/secondary-default'],
  'button/color/button-secondary-bg-hover': semanticTokensLightColor['background/secondary-hover'],
  'button/color/button-secondary-bg-press': semanticTokensLightColor['background/secondary-press'],
  'button/color/button-secondary-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-secondary-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-secondary-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-secondary-fg': semanticTokensLightColor['foreground/secondary-default'],
  'button/color/button-secondary-text': semanticTokensLightColor['text/secondary'],
  'button/color/button-secondary-text-hover': semanticTokensLightColor['text/secondary-hover'],
  'button/color/button-secondary-text-press': semanticTokensLightColor['text/secondary-press'],
  'button/color/button-tertiary-bg-default': semanticTokensLightColor['background/tertiary-default'],
  'button/color/button-tertiary-bg-hover': semanticTokensLightColor['background/tertiary-hover'],
  'button/color/button-tertiary-bg-press': semanticTokensLightColor['background/tertiary-press'],
  'button/color/button-tertiary-border': semanticTokensLightColor['background/transparent'],
  'button/color/button-tertiary-border-hover': semanticTokensLightColor['background/transparent'],
  'button/color/button-tertiary-border-press': semanticTokensLightColor['background/transparent'],
  'button/color/button-tertiary-fg': semanticTokensLightColor['foreground/tertiary-default'],
  'button/color/button-tertiary-text': semanticTokensLightColor['text/tertiary'],
  'button/color/button-tertiary-text-hover': semanticTokensLightColor['text/tertiary-hover'],
  'button/color/button-tertiary-text-press': semanticTokensLightColor['text/tertiary-press'],
  'carousel-stepper/color/carousel-stepper-default': semanticTokensLightColor['carousel-stepper/default'],
  'carousel-stepper/color/carousel-stepper-selected': semanticTokensLightColor['carousel-stepper/selected'],
  'chart-income-expenses/color/background': semanticTokensLightColor['background/surface'],
  'chart-income-expenses/color/bar-deselected': semanticTokensLightColor['chart-background-subdued/iota'],
  'chart-income-expenses/color/bar-expenses': semanticTokensLightColor['chart-background/zeta'],
  'chart-income-expenses/color/bar-income': semanticTokensLightColor['chart-background/delta'],
  'chart-income-expenses/color/foreground': semanticTokensLightColor['foreground/surface-default'],
  'chart-income-expenses/color/foreground-subdued': semanticTokensLightColor['foreground/surface-subdued'],
  'chart-merchant-category/color/background': semanticTokensLightColor['background/surface'],
  'chart-merchant-category/color/category-1-icon-bg': semanticTokensLightColor['chart-background-subdued/theta'],
  'chart-merchant-category/color/category-1-icon-fg': semanticTokensLightColor['chart-icon/theta'],
  'chart-merchant-category/color/category-1-segment': semanticTokensLightColor['chart-background/theta'],
  'chart-merchant-category/color/category-2-icon-bg': semanticTokensLightColor['chart-background-subdued/beta'],
  'chart-merchant-category/color/category-2-icon-fg': semanticTokensLightColor['chart-icon/beta'],
  'chart-merchant-category/color/category-2-segment': semanticTokensLightColor['chart-background/beta'],
  'chart-merchant-category/color/category-3-icon-bg': semanticTokensLightColor['chart-background-subdued/delta'],
  'chart-merchant-category/color/category-3-icon-fg': semanticTokensLightColor['chart-icon/delta'],
  'chart-merchant-category/color/category-3-segment': semanticTokensLightColor['chart-background/delta'],
  'chart-merchant-category/color/category-4-icon-bg': semanticTokensLightColor['chart-background-subdued/eta'],
  'chart-merchant-category/color/category-4-icon-fg': semanticTokensLightColor['chart-icon/eta'],
  'chart-merchant-category/color/category-4-segment': semanticTokensLightColor['chart-background/eta'],
  'chart-merchant-category/color/category-5-icon-bg': semanticTokensLightColor['chart-background-subdued/iota'],
  'chart-merchant-category/color/category-5-icon-fg': semanticTokensLightColor['chart-icon/iota'],
  'chart-merchant-category/color/category-5-segment': semanticTokensLightColor['chart-background/iota'],
  'chart-merchant-category/color/foreground': semanticTokensLightColor['foreground/surface-default'],
  'chart-merchant-category/color/foreground-subdued': semanticTokensLightColor['foreground/surface-subdued'],
  'chart-merchant-category/color/segment-empty': semanticTokensLightColor['chart-background-subdued/iota'],
  'chart-payment-limit/color/background': semanticTokensLightColor['background/surface'],
  'chart-payment-limit/color/foreground': semanticTokensLightColor['foreground/surface-default'],
  'chart-payment-limit/color/foreground-subdued': semanticTokensLightColor['foreground/surface-subdued'],
  'chart-payment-limit/color/payment-left': semanticTokensLightColor['chart-background-subdued/iota'],
  'chart-payment-limit/color/payment-spent': semanticTokensLightColor['chart-background/zeta'],
  'checkbox/color/checkbox-bg-default': semanticTokensLightColor['background/transparent'],
  'checkbox/color/checkbox-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'checkbox/color/checkbox-border-danger': semanticTokensLightColor['border/danger'],
  'checkbox/color/checkbox-border-default': semanticTokensLightColor['border/surface'],
  'checkbox/color/checkbox-border-selected': semanticTokensLightColor['background/surface-inverse'],
  'checkbox/color/checkbox-card-bg-default': semanticTokensLightColor['background/transparent'],
  'checkbox/color/checkbox-card-bg-selected': semanticTokensLightColor['background/primary-subdued'],
  'checkbox/color/checkbox-card-border-danger': semanticTokensLightColor['border/danger'],
  'checkbox/color/checkbox-card-border-default': semanticTokensLightColor['border/surface-subdued'],
  'checkbox/color/checkbox-card-border-selected': semanticTokensLightColor['border/primary'],
  'checkbox/color/checkbox-fg-danger': semanticTokensLightColor['text/danger'],
  'checkbox/color/checkbox-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'checkbox/color/checkbox-fg-selected': semanticTokensLightColor['foreground/primary-subdued'],
  'checkbox/color/checkbox-fg-selected-subdued': semanticTokensLightColor['foreground/app-subdued'],
  'checkbox/color/checkbox-fg-subdued': semanticTokensLightColor['foreground/app-subdued'],
  'checkbox/color/checkbox-icon-default': semanticTokensLightColor['icon/surface'],
  'checkbox/color/checkbox-icon-selected': semanticTokensLightColor['icon/surface-inverse'],
  'feature/color/feature-icon-bg': semanticTokensLightColor['background/primary-subdued'],
  'feature/color/feature-icon-fg': semanticTokensLightColor['foreground/primary-subdued'],
  'form/color/form-bg-default': semanticTokensLightColor['background/transparent'],
  'form/color/form-bg-selected': semanticTokensLightColor['background/transparent'],
  'form/color/form-border-danger': semanticTokensLightColor['border/danger'],
  'form/color/form-border-default': semanticTokensLightColor['border/surface'],
  'form/color/form-border-selected': semanticTokensLightColor['border/primary'],
  'form/color/form-fg-danger': semanticTokensLightColor['text/danger'],
  'form/color/form-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'form/color/form-fg-selected': semanticTokensLightColor['foreground/surface-default'],
  'form/color/form-fg-selected-subdued': semanticTokensLightColor['foreground/app-subdued'],
  'form/color/form-fg-subdued': semanticTokensLightColor['foreground/app-subdued'],
  'form/color/form-icon-default': semanticTokensLightColor['icon/surface'],
  'form/color/form-icon-selected': semanticTokensLightColor['icon/surface'],
  'mobile-header/color/header-bg': semanticTokensLightColor['background/primary-default'],
  'mobile-header/color/header-button-bg': semanticTokensLightColor['background/surface'],
  'mobile-header/color/header-button-fg': semanticTokensLightColor['icon/app'],
  'mobile-header/color/header-button-text': semanticTokensLightColor['text/app'],
  'mobile-header/color/header-text-primary': semanticTokensLightColor['text/app'],
  'mobile-header/color/header-text-secondary': semanticTokensLightColor['text/app'],
  'navigation/color/nav-bg-default': semanticTokensLightColor['background/secondary-default'],
  'navigation/color/nav-bg-hover': semanticTokensLightColor['background/secondary-hover'],
  'navigation/color/nav-bg-selected': semanticTokensLightColor['background/secondary-press'],
  'navigation/color/nav-fg-default': semanticTokensLightColor['foreground/secondary-default'],
  'navigation/color/nav-fg-subdued': semanticTokensLightColor['foreground/secondary-subdued'],
  'navigation/color/nav-focus': semanticTokensLightColor['other/focus'],
  'navigation/color/nav-icon-selected': semanticTokensLightColor['background/surface-highlight'],
  'navigation/color/nav-icon-text-selected': semanticTokensLightColor['foreground/secondary-default'],
  'radio/color/radio-bg-default': semanticTokensLightColor['background/transparent'],
  'radio/color/radio-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'radio/color/radio-border-danger': semanticTokensLightColor['border/danger'],
  'radio/color/radio-border-default': semanticTokensLightColor['border/surface'],
  'radio/color/radio-border-selected': semanticTokensLightColor['background/surface-inverse'],
  'radio/color/radio-card-bg-default': semanticTokensLightColor['background/transparent'],
  'radio/color/radio-card-bg-selected': semanticTokensLightColor['background/primary-subdued'],
  'radio/color/radio-card-border-danger': semanticTokensLightColor['border/danger'],
  'radio/color/radio-card-border-default': semanticTokensLightColor['border/surface-subdued'],
  'radio/color/radio-card-border-selected': semanticTokensLightColor['border/primary'],
  'radio/color/radio-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'radio/color/radio-fg-selected': semanticTokensLightColor['foreground/primary-subdued'],
  'radio/color/radio-icon-default': semanticTokensLightColor['icon/surface'],
  'radio/color/radio-icon-selected': semanticTokensLightColor['icon/surface-inverse'],
  'select/color/select-bg-default': semanticTokensLightColor['background/surface'],
  'select/color/select-bg-hover': semanticTokensLightColor['background/primary-subdued'],
  'select/color/select-fg-accent': semanticTokensLightColor['text/primary'],
  'select/color/select-fg-accent-hover': semanticTokensLightColor['text/primary-hover'],
  'select/color/select-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'select/color/select-fg-hover': semanticTokensLightColor['foreground/primary-subdued'],
  'select/color/select-fg-subdued': semanticTokensLightColor['foreground/surface-subdued'],
  'select/color/select-fg-subdued-hover': semanticTokensLightColor['text/primary'],
  'step-navigation/color/step-bg-content': semanticTokensLightColor['background/surface'],
  'step-navigation/color/step-bg-default': semanticTokensLightColor['background/secondary-default'],
  'step-navigation/color/step-bg-hover': semanticTokensLightColor['background/secondary-hover'],
  'step-navigation/color/step-bg-selected': semanticTokensLightColor['background/secondary-press'],
  'step-navigation/color/step-fg-default': semanticTokensLightColor['foreground/secondary-default'],
  'step-navigation/color/step-fg-subdued': semanticTokensLightColor['foreground/secondary-subdued'],
  'step-navigation/color/step-focus': semanticTokensLightColor['other/focus'],
  'step-navigation/color/step-icon-selected': semanticTokensLightColor['background/surface-highlight'],
  'step-navigation/color/step-icon-text-selected': semanticTokensLightColor['background/secondary-default'],
  'switch/color/switch-bg-default': semanticTokensLightColor['background/transparent'],
  'switch/color/switch-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'switch/color/switch-border-default': semanticTokensLightColor['border/surface'],
  'switch/color/switch-border-selected': semanticTokensLightColor['background/surface-inverse'],
  'switch/color/switch-card-bg-default': semanticTokensLightColor['background/transparent'],
  'switch/color/switch-card-bg-selected': semanticTokensLightColor['background/primary-subdued'],
  'switch/color/switch-card-border-default': semanticTokensLightColor['border/surface-subdued'],
  'switch/color/switch-card-border-selected': semanticTokensLightColor['border/primary'],
  'switch/color/switch-fg-default': semanticTokensLightColor['foreground/surface-default'],
  'switch/color/switch-fg-selected': semanticTokensLightColor['foreground/primary-subdued'],
  'switch/color/switch-icon-default': semanticTokensLightColor['icon/surface'],
  'switch/color/switch-icon-selected': semanticTokensLightColor['icon/surface-inverse'],
  'tabs/color/tab-bg-default': semanticTokensLightColor['background/surface'],
  'tabs/color/tab-bg-disabled': semanticTokensLightColor['background/disabled'],
  'tabs/color/tab-bg-hover': semanticTokensLightColor['background/primary-subdued'],
  'tabs/color/tab-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'tabs/color/tab-container': semanticTokensLightColor['background/surface'],
  'tabs/color/tab-dot-selected': semanticTokensLightColor['icon/danger'],
  'tabs/color/tab-fg-default': semanticTokensLightColor['text/app-subdued'],
  'tabs/color/tab-fg-selected': semanticTokensLightColor['foreground/surface-inverse'],
  'toast/color/toast-bg-attention': semanticTokensLightColor['background/surface-inverse'],
  'toast/color/toast-bg-danger': semanticTokensLightColor['background/danger'],
  'toast/color/toast-bg-info': semanticTokensLightColor['background/info'],
  'toast/color/toast-bg-success': semanticTokensLightColor['background/success'],
  'toast/color/toast-bg-warning': semanticTokensLightColor['background/warning'],
  'toast/color/toast-fg-attention': semanticTokensLightColor['foreground/surface-inverse'],
  'toast/color/toast-fg-danger': semanticTokensLightColor['foreground/danger-default'],
  'toast/color/toast-fg-info': semanticTokensLightColor['foreground/info-default'],
  'toast/color/toast-fg-success': semanticTokensLightColor['foreground/success-default'],
  'toast/color/toast-fg-warning': semanticTokensLightColor['foreground/warning-default'],
  'toggle/color/toggle-bg-default': semanticTokensLightColor['background/transparent'],
  'toggle/color/toggle-bg-disabled': semanticTokensLightColor['background/disabled'],
  'toggle/color/toggle-bg-hover': semanticTokensLightColor['background/primary-subdued'],
  'toggle/color/toggle-bg-selected': semanticTokensLightColor['background/surface-inverse'],
  'toggle/color/toggle-border-default': semanticTokensLightColor['border/primary'],
  'toggle/color/toggle-border-disabled': semanticTokensLightColor['border/disabled'],
  'toggle/color/toggle-border-selected': semanticTokensLightColor['background/surface-inverse'],
  'toggle/color/toggle-fg-default': semanticTokensLightColor['text/primary'],
  'toggle/color/toggle-fg-disabled': semanticTokensLightColor['foreground/disabled'],
  'toggle/color/toggle-fg-selected': semanticTokensLightColor['foreground/surface-inverse'],
} as const;
type ComponentTokensBaseTheme = VariablesCollection<typeof componentTokensLightColor>;

export const componentTokensDarkColor: ComponentTokensBaseTheme = {
  'alert/color/attention/alert-bg-attention': semanticTokensDarkColor['background/surface-inverse'],
  'alert/color/attention/alert-border-attention': semanticTokensDarkColor['background/transparent'],
  'alert/color/attention/alert-fg-attention': semanticTokensDarkColor['foreground/surface-inverse'],
  'alert/color/attention/alert-fg-attention-subdued': semanticTokensDarkColor['foreground/surface-inverse-subdued'],
  'alert/color/attention/alert-icon-attention': semanticTokensDarkColor['icon/surface'],
  'alert/color/attention/alert-text-attention': semanticTokensDarkColor['text/surface'],
  'alert/color/danger/alert-bg-danger': semanticTokensDarkColor['background/danger'],
  'alert/color/danger/alert-border-danger': semanticTokensDarkColor['background/transparent'],
  'alert/color/danger/alert-fg-danger': semanticTokensDarkColor['foreground/danger-default'],
  'alert/color/danger/alert-fg-danger-subdued': semanticTokensDarkColor['foreground/danger-subdued'],
  'alert/color/danger/alert-icon-danger': semanticTokensDarkColor['icon/danger'],
  'alert/color/danger/alert-text-danger': semanticTokensDarkColor['text/danger'],
  'alert/color/info/alert-bg-info': semanticTokensDarkColor['background/info'],
  'alert/color/info/alert-border-info': semanticTokensDarkColor['background/transparent'],
  'alert/color/info/alert-fg-info': semanticTokensDarkColor['foreground/info-default'],
  'alert/color/info/alert-fg-info-subdued': semanticTokensDarkColor['foreground/info-subdued'],
  'alert/color/info/alert-icon-info': semanticTokensDarkColor['icon/info'],
  'alert/color/info/alert-text-info': semanticTokensDarkColor['text/info'],
  'alert/color/neutral/alert-bg-neutral': semanticTokensDarkColor['background/neutral'],
  'alert/color/neutral/alert-border-neutral': semanticTokensDarkColor['background/transparent'],
  'alert/color/neutral/alert-fg-neutral': semanticTokensDarkColor['foreground/neutral'],
  'alert/color/neutral/alert-fg-neutral-subdued': semanticTokensDarkColor['foreground/neutral'],
  'alert/color/neutral/alert-icon-neutral': semanticTokensDarkColor['foreground/neutral'],
  'alert/color/neutral/alert-text-neutral': semanticTokensDarkColor['foreground/neutral'],
  'alert/color/success/alert-bg-success': semanticTokensDarkColor['background/success'],
  'alert/color/success/alert-border-success': semanticTokensDarkColor['background/transparent'],
  'alert/color/success/alert-fg-subdued': semanticTokensDarkColor['foreground/success-subdued'],
  'alert/color/success/alert-fg-success': semanticTokensDarkColor['foreground/success-default'],
  'alert/color/success/alert-icon-success': semanticTokensDarkColor['icon/success'],
  'alert/color/success/alert-text-success': semanticTokensDarkColor['text/success'],
  'alert/color/warning/alert-bg-warning': semanticTokensDarkColor['background/warning'],
  'alert/color/warning/alert-border-warning': semanticTokensDarkColor['background/transparent'],
  'alert/color/warning/alert-fg-warning': semanticTokensDarkColor['foreground/warning-default'],
  'alert/color/warning/alert-fg-warning-subdued': semanticTokensDarkColor['foreground/warning-subdued'],
  'alert/color/warning/alert-icon-warning': semanticTokensDarkColor['icon/warning'],
  'alert/color/warning/alert-text-warning': semanticTokensDarkColor['text/warning'],
  'badge/color/alpha/badge-bg-alpha': semanticTokensDarkColor['chart-background-subdued/alpha'],
  'badge/color/alpha/badge-fg-alpha': semanticTokensDarkColor['chart-foreground-subdued/alpha'],
  'badge/color/attention/badge-bg-attention': semanticTokensDarkColor['background/surface-inverse'],
  'badge/color/attention/badge-fg-attention': semanticTokensDarkColor['foreground/surface-inverse'],
  'badge/color/beta/badge-bg-beta': semanticTokensDarkColor['chart-background-subdued/beta'],
  'badge/color/beta/badge-fg-beta': semanticTokensDarkColor['chart-foreground-subdued/beta'],
  'badge/color/danger/badge-bg-danger': semanticTokensDarkColor['background/danger'],
  'badge/color/danger/badge-fg-danger': semanticTokensDarkColor['foreground/danger-default'],
  'badge/color/delta/badge-bg-delta': semanticTokensDarkColor['chart-background-subdued/delta'],
  'badge/color/delta/badge-fg-delta': semanticTokensDarkColor['chart-foreground-subdued/delta'],
  'badge/color/epsilon/badge-bg-epsilon': semanticTokensDarkColor['chart-background-subdued/epsilon'],
  'badge/color/epsilon/badge-fg-epsilon': semanticTokensDarkColor['chart-foreground-subdued/epsilon'],
  'badge/color/eta/badge-bg-eta': semanticTokensDarkColor['chart-background-subdued/eta'],
  'badge/color/eta/badge-fg-eta': semanticTokensDarkColor['chart-foreground-subdued/eta'],
  'badge/color/gamma/badge-bg-gamma': semanticTokensDarkColor['chart-background-subdued/gamma'],
  'badge/color/gamma/badge-fg-gamma': semanticTokensDarkColor['chart-foreground-subdued/gamma'],
  'badge/color/info/badge-bg-info': semanticTokensDarkColor['background/info'],
  'badge/color/info/badge-fg-info': semanticTokensDarkColor['foreground/info-default'],
  'badge/color/iota/badge-bg-iota': semanticTokensDarkColor['chart-background-subdued/iota'],
  'badge/color/iota/badge-fg-iota': semanticTokensDarkColor['chart-foreground-subdued/iota'],
  'badge/color/neutral/badge-bg-neutral': semanticTokensDarkColor['background/neutral'],
  'badge/color/neutral/badge-fg-neutral': semanticTokensDarkColor['foreground/neutral'],
  'badge/color/success/badge-bg-success': semanticTokensDarkColor['background/success'],
  'badge/color/success/badge-fg-success': semanticTokensDarkColor['foreground/success-default'],
  'badge/color/theta/badge-bg-theta': semanticTokensDarkColor['chart-background-subdued/theta'],
  'badge/color/theta/badge-fg-theta': semanticTokensDarkColor['chart-foreground-subdued/theta'],
  'badge/color/warning/badge-bg-warning': semanticTokensDarkColor['background/warning'],
  'badge/color/warning/badge-fg-warning': semanticTokensDarkColor['foreground/warning-default'],
  'badge/color/zeta/badge-bg-zeta': semanticTokensDarkColor['chart-background-subdued/zeta'],
  'badge/color/zeta/badge-fg-zeta': semanticTokensDarkColor['chart-foreground-subdued/zeta'],
  'button/color/button-danger-bg-default': semanticTokensDarkColor['background/danger'],
  'button/color/button-danger-bg-hover': semanticTokensDarkColor['background/danger-hover'],
  'button/color/button-danger-bg-press': semanticTokensDarkColor['background/danger-press'],
  'button/color/button-danger-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-danger-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-danger-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-danger-fg': semanticTokensDarkColor['foreground/danger-default'],
  'button/color/button-danger-text': semanticTokensDarkColor['text/danger'],
  'button/color/button-danger-text-hover': semanticTokensDarkColor['text/danger-hover'],
  'button/color/button-danger-text-press': semanticTokensDarkColor['text/danger-press'],
  'button/color/button-disabled-bg': semanticTokensDarkColor['background/disabled'],
  'button/color/button-disabled-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-disabled-fg': semanticTokensDarkColor['foreground/disabled'],
  'button/color/button-focus-border': semanticTokensDarkColor['other/focus'],
  'button/color/button-inverse-bg-default': semanticTokensDarkColor['other/white-alpha-50'],
  'button/color/button-inverse-bg-hover': semanticTokensDarkColor['other/white-alpha-60'],
  'button/color/button-inverse-bg-press': semanticTokensDarkColor['other/white-alpha-50'],
  'button/color/button-inverse-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-inverse-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-inverse-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-inverse-fg': semanticTokensDarkColor['foreground/surface-default'],
  'button/color/button-outlined-danger-bg': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-danger-bg-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-danger-bg-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-danger-border': semanticTokensDarkColor['border/danger'],
  'button/color/button-outlined-danger-border-hover': semanticTokensDarkColor['border/danger-hover'],
  'button/color/button-outlined-danger-border-press': semanticTokensDarkColor['border/danger-press'],
  'button/color/button-outlined-disabled-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-primary-bg': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-primary-bg-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-primary-bg-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-primary-border': semanticTokensDarkColor['border/primary'],
  'button/color/button-outlined-primary-border-hover': semanticTokensDarkColor['border/primary-hover'],
  'button/color/button-outlined-primary-border-press': semanticTokensDarkColor['border/primary-press'],
  'button/color/button-outlined-secondary-bg': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-secondary-bg-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-secondary-bg-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-secondary-border': semanticTokensDarkColor['border/secondary'],
  'button/color/button-outlined-secondary-border-hover': semanticTokensDarkColor['border/secondary-hover'],
  'button/color/button-outlined-secondary-border-press': semanticTokensDarkColor['border/secondary-press'],
  'button/color/button-outlined-tertiary-bg': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-tertiary-bg-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-tertiary-bg-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-outlined-tertiary-border': semanticTokensDarkColor['background/tertiary-default'],
  'button/color/button-outlined-tertiary-border-hover': semanticTokensDarkColor['background/tertiary-hover'],
  'button/color/button-outlined-tertiary-border-press': semanticTokensDarkColor['background/tertiary-press'],
  'button/color/button-primary-bg-default': semanticTokensDarkColor['background/primary-default'],
  'button/color/button-primary-bg-hover': semanticTokensDarkColor['background/primary-hover'],
  'button/color/button-primary-bg-press': semanticTokensDarkColor['background/primary-press'],
  'button/color/button-primary-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-primary-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-primary-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-primary-fg': semanticTokensDarkColor['foreground/primary-default'],
  'button/color/button-primary-text': semanticTokensDarkColor['text/primary'],
  'button/color/button-primary-text-hover': semanticTokensDarkColor['text/primary-hover'],
  'button/color/button-primary-text-press': semanticTokensDarkColor['text/primary-press'],
  'button/color/button-secondary-bg-default': semanticTokensDarkColor['background/secondary-default'],
  'button/color/button-secondary-bg-hover': semanticTokensDarkColor['background/secondary-hover'],
  'button/color/button-secondary-bg-press': semanticTokensDarkColor['background/secondary-press'],
  'button/color/button-secondary-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-secondary-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-secondary-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-secondary-fg': semanticTokensDarkColor['foreground/secondary-default'],
  'button/color/button-secondary-text': semanticTokensDarkColor['text/secondary'],
  'button/color/button-secondary-text-hover': semanticTokensDarkColor['text/secondary-hover'],
  'button/color/button-secondary-text-press': semanticTokensDarkColor['text/secondary-press'],
  'button/color/button-tertiary-bg-default': semanticTokensDarkColor['background/tertiary-default'],
  'button/color/button-tertiary-bg-hover': semanticTokensDarkColor['background/tertiary-hover'],
  'button/color/button-tertiary-bg-press': semanticTokensDarkColor['background/tertiary-press'],
  'button/color/button-tertiary-border': semanticTokensDarkColor['background/transparent'],
  'button/color/button-tertiary-border-hover': semanticTokensDarkColor['background/transparent'],
  'button/color/button-tertiary-border-press': semanticTokensDarkColor['background/transparent'],
  'button/color/button-tertiary-fg': semanticTokensDarkColor['foreground/tertiary-default'],
  'button/color/button-tertiary-text': semanticTokensDarkColor['text/tertiary'],
  'button/color/button-tertiary-text-hover': semanticTokensDarkColor['text/tertiary-hover'],
  'button/color/button-tertiary-text-press': semanticTokensDarkColor['text/tertiary-press'],
  'carousel-stepper/color/carousel-stepper-default': semanticTokensDarkColor['carousel-stepper/default'],
  'carousel-stepper/color/carousel-stepper-selected': semanticTokensDarkColor['carousel-stepper/selected'],
  'chart-income-expenses/color/background': semanticTokensDarkColor['background/surface'],
  'chart-income-expenses/color/bar-deselected': semanticTokensDarkColor['chart-background-subdued/iota'],
  'chart-income-expenses/color/bar-expenses': semanticTokensDarkColor['chart-background/zeta'],
  'chart-income-expenses/color/bar-income': semanticTokensDarkColor['chart-background/delta'],
  'chart-income-expenses/color/foreground': semanticTokensDarkColor['foreground/surface-default'],
  'chart-income-expenses/color/foreground-subdued': semanticTokensDarkColor['foreground/surface-subdued'],
  'chart-merchant-category/color/background': semanticTokensDarkColor['background/surface'],
  'chart-merchant-category/color/category-1-icon-bg': semanticTokensDarkColor['chart-background-subdued/theta'],
  'chart-merchant-category/color/category-1-icon-fg': semanticTokensDarkColor['chart-icon/theta'],
  'chart-merchant-category/color/category-1-segment': semanticTokensDarkColor['chart-background/theta'],
  'chart-merchant-category/color/category-2-icon-bg': semanticTokensDarkColor['chart-background-subdued/beta'],
  'chart-merchant-category/color/category-2-icon-fg': semanticTokensDarkColor['chart-icon/beta'],
  'chart-merchant-category/color/category-2-segment': semanticTokensDarkColor['chart-background/beta'],
  'chart-merchant-category/color/category-3-icon-bg': semanticTokensDarkColor['chart-background-subdued/delta'],
  'chart-merchant-category/color/category-3-icon-fg': semanticTokensDarkColor['chart-icon/delta'],
  'chart-merchant-category/color/category-3-segment': semanticTokensDarkColor['chart-background/delta'],
  'chart-merchant-category/color/category-4-icon-bg': semanticTokensDarkColor['chart-background-subdued/eta'],
  'chart-merchant-category/color/category-4-icon-fg': semanticTokensDarkColor['chart-icon/eta'],
  'chart-merchant-category/color/category-4-segment': semanticTokensDarkColor['chart-background/eta'],
  'chart-merchant-category/color/category-5-icon-bg': semanticTokensDarkColor['chart-background-subdued/iota'],
  'chart-merchant-category/color/category-5-icon-fg': semanticTokensDarkColor['chart-icon/iota'],
  'chart-merchant-category/color/category-5-segment': semanticTokensDarkColor['chart-background/iota'],
  'chart-merchant-category/color/foreground': semanticTokensDarkColor['foreground/surface-default'],
  'chart-merchant-category/color/foreground-subdued': semanticTokensDarkColor['foreground/surface-subdued'],
  'chart-merchant-category/color/segment-empty': semanticTokensDarkColor['chart-background-subdued/iota'],
  'chart-payment-limit/color/background': semanticTokensDarkColor['background/surface'],
  'chart-payment-limit/color/foreground': semanticTokensDarkColor['foreground/surface-default'],
  'chart-payment-limit/color/foreground-subdued': semanticTokensDarkColor['foreground/surface-subdued'],
  'chart-payment-limit/color/payment-left': semanticTokensDarkColor['chart-background-subdued/iota'],
  'chart-payment-limit/color/payment-spent': semanticTokensDarkColor['chart-background/zeta'],
  'checkbox/color/checkbox-bg-default': semanticTokensDarkColor['background/transparent'],
  'checkbox/color/checkbox-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'checkbox/color/checkbox-border-danger': semanticTokensDarkColor['border/danger'],
  'checkbox/color/checkbox-border-default': semanticTokensDarkColor['border/surface'],
  'checkbox/color/checkbox-border-selected': semanticTokensDarkColor['background/surface-inverse'],
  'checkbox/color/checkbox-card-bg-default': semanticTokensDarkColor['background/transparent'],
  'checkbox/color/checkbox-card-bg-selected': semanticTokensDarkColor['background/primary-subdued'],
  'checkbox/color/checkbox-card-border-danger': semanticTokensDarkColor['border/danger'],
  'checkbox/color/checkbox-card-border-default': semanticTokensDarkColor['border/surface-subdued'],
  'checkbox/color/checkbox-card-border-selected': semanticTokensDarkColor['border/primary'],
  'checkbox/color/checkbox-fg-danger': semanticTokensDarkColor['text/danger'],
  'checkbox/color/checkbox-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'checkbox/color/checkbox-fg-selected': semanticTokensDarkColor['foreground/primary-subdued'],
  'checkbox/color/checkbox-fg-selected-subdued': semanticTokensDarkColor['foreground/app-subdued'],
  'checkbox/color/checkbox-fg-subdued': semanticTokensDarkColor['foreground/app-subdued'],
  'checkbox/color/checkbox-icon-default': semanticTokensDarkColor['icon/surface'],
  'checkbox/color/checkbox-icon-selected': semanticTokensDarkColor['icon/surface-inverse'],
  'feature/color/feature-icon-bg': semanticTokensDarkColor['background/primary-subdued'],
  'feature/color/feature-icon-fg': semanticTokensDarkColor['foreground/primary-subdued'],
  'form/color/form-bg-default': semanticTokensDarkColor['background/transparent'],
  'form/color/form-bg-selected': semanticTokensDarkColor['background/transparent'],
  'form/color/form-border-danger': semanticTokensDarkColor['border/danger'],
  'form/color/form-border-default': semanticTokensDarkColor['border/surface'],
  'form/color/form-border-selected': semanticTokensDarkColor['border/primary'],
  'form/color/form-fg-danger': semanticTokensDarkColor['text/danger'],
  'form/color/form-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'form/color/form-fg-selected': semanticTokensDarkColor['foreground/surface-default'],
  'form/color/form-fg-selected-subdued': semanticTokensDarkColor['foreground/app-subdued'],
  'form/color/form-fg-subdued': semanticTokensDarkColor['foreground/app-subdued'],
  'form/color/form-icon-default': semanticTokensDarkColor['icon/surface'],
  'form/color/form-icon-selected': semanticTokensDarkColor['icon/surface'],
  'mobile-header/color/header-bg': semanticTokensDarkColor['background/primary-default'],
  'mobile-header/color/header-button-bg': semanticTokensDarkColor['background/surface'],
  'mobile-header/color/header-button-fg': semanticTokensDarkColor['icon/app'],
  'mobile-header/color/header-button-text': semanticTokensDarkColor['text/app'],
  'mobile-header/color/header-text-primary': semanticTokensDarkColor['text/app'],
  'mobile-header/color/header-text-secondary': semanticTokensDarkColor['text/app'],
  'navigation/color/nav-bg-default': semanticTokensDarkColor['background/secondary-default'],
  'navigation/color/nav-bg-hover': semanticTokensDarkColor['background/secondary-hover'],
  'navigation/color/nav-bg-selected': semanticTokensDarkColor['background/secondary-press'],
  'navigation/color/nav-fg-default': semanticTokensDarkColor['foreground/secondary-default'],
  'navigation/color/nav-fg-subdued': semanticTokensDarkColor['foreground/secondary-subdued'],
  'navigation/color/nav-focus': semanticTokensDarkColor['other/focus'],
  'navigation/color/nav-icon-selected': semanticTokensDarkColor['background/surface-highlight'],
  'navigation/color/nav-icon-text-selected': semanticTokensDarkColor['foreground/secondary-default'],
  'radio/color/radio-bg-default': semanticTokensDarkColor['background/transparent'],
  'radio/color/radio-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'radio/color/radio-border-danger': semanticTokensDarkColor['border/danger'],
  'radio/color/radio-border-default': semanticTokensDarkColor['border/surface'],
  'radio/color/radio-border-selected': semanticTokensDarkColor['background/surface-inverse'],
  'radio/color/radio-card-bg-default': semanticTokensDarkColor['background/transparent'],
  'radio/color/radio-card-bg-selected': semanticTokensDarkColor['background/primary-subdued'],
  'radio/color/radio-card-border-danger': semanticTokensDarkColor['border/danger'],
  'radio/color/radio-card-border-default': semanticTokensDarkColor['border/surface-subdued'],
  'radio/color/radio-card-border-selected': semanticTokensDarkColor['border/primary'],
  'radio/color/radio-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'radio/color/radio-fg-selected': semanticTokensDarkColor['foreground/primary-subdued'],
  'radio/color/radio-icon-default': semanticTokensDarkColor['icon/surface'],
  'radio/color/radio-icon-selected': semanticTokensDarkColor['icon/surface-inverse'],
  'select/color/select-bg-default': semanticTokensDarkColor['background/surface'],
  'select/color/select-bg-hover': semanticTokensDarkColor['background/primary-subdued'],
  'select/color/select-fg-accent': semanticTokensDarkColor['text/primary'],
  'select/color/select-fg-accent-hover': semanticTokensDarkColor['text/primary-hover'],
  'select/color/select-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'select/color/select-fg-hover': semanticTokensDarkColor['foreground/primary-subdued'],
  'select/color/select-fg-subdued': semanticTokensDarkColor['foreground/surface-subdued'],
  'select/color/select-fg-subdued-hover': semanticTokensDarkColor['text/primary'],
  'step-navigation/color/step-bg-content': semanticTokensDarkColor['background/surface'],
  'step-navigation/color/step-bg-default': semanticTokensDarkColor['background/secondary-default'],
  'step-navigation/color/step-bg-hover': semanticTokensDarkColor['background/secondary-hover'],
  'step-navigation/color/step-bg-selected': semanticTokensDarkColor['background/secondary-press'],
  'step-navigation/color/step-fg-default': semanticTokensDarkColor['foreground/secondary-default'],
  'step-navigation/color/step-fg-subdued': semanticTokensDarkColor['foreground/secondary-subdued'],
  'step-navigation/color/step-focus': semanticTokensDarkColor['other/focus'],
  'step-navigation/color/step-icon-selected': semanticTokensDarkColor['background/surface-highlight'],
  'step-navigation/color/step-icon-text-selected': semanticTokensDarkColor['background/secondary-default'],
  'switch/color/switch-bg-default': semanticTokensDarkColor['background/transparent'],
  'switch/color/switch-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'switch/color/switch-border-default': semanticTokensDarkColor['border/surface'],
  'switch/color/switch-border-selected': semanticTokensDarkColor['background/surface-inverse'],
  'switch/color/switch-card-bg-default': semanticTokensDarkColor['background/transparent'],
  'switch/color/switch-card-bg-selected': semanticTokensDarkColor['background/primary-subdued'],
  'switch/color/switch-card-border-default': semanticTokensDarkColor['border/surface-subdued'],
  'switch/color/switch-card-border-selected': semanticTokensDarkColor['border/primary'],
  'switch/color/switch-fg-default': semanticTokensDarkColor['foreground/surface-default'],
  'switch/color/switch-fg-selected': semanticTokensDarkColor['foreground/primary-subdued'],
  'switch/color/switch-icon-default': semanticTokensDarkColor['icon/surface'],
  'switch/color/switch-icon-selected': semanticTokensDarkColor['icon/surface-inverse'],
  'tabs/color/tab-bg-default': semanticTokensDarkColor['background/surface'],
  'tabs/color/tab-bg-disabled': semanticTokensDarkColor['background/disabled'],
  'tabs/color/tab-bg-hover': semanticTokensDarkColor['background/primary-subdued'],
  'tabs/color/tab-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'tabs/color/tab-container': semanticTokensDarkColor['background/surface'],
  'tabs/color/tab-dot-selected': semanticTokensDarkColor['icon/danger'],
  'tabs/color/tab-fg-default': semanticTokensDarkColor['text/app-subdued'],
  'tabs/color/tab-fg-selected': semanticTokensDarkColor['foreground/surface-inverse'],
  'toast/color/toast-bg-attention': semanticTokensDarkColor['background/surface-inverse'],
  'toast/color/toast-bg-danger': semanticTokensDarkColor['background/danger'],
  'toast/color/toast-bg-info': semanticTokensDarkColor['background/info'],
  'toast/color/toast-bg-success': semanticTokensDarkColor['background/success'],
  'toast/color/toast-bg-warning': semanticTokensDarkColor['background/warning'],
  'toast/color/toast-fg-attention': semanticTokensDarkColor['foreground/surface-inverse'],
  'toast/color/toast-fg-danger': semanticTokensDarkColor['foreground/danger-default'],
  'toast/color/toast-fg-info': semanticTokensDarkColor['foreground/info-default'],
  'toast/color/toast-fg-success': semanticTokensDarkColor['foreground/success-default'],
  'toast/color/toast-fg-warning': semanticTokensDarkColor['foreground/warning-default'],
  'toggle/color/toggle-bg-default': semanticTokensDarkColor['background/transparent'],
  'toggle/color/toggle-bg-disabled': semanticTokensDarkColor['background/disabled'],
  'toggle/color/toggle-bg-hover': semanticTokensDarkColor['background/primary-subdued'],
  'toggle/color/toggle-bg-selected': semanticTokensDarkColor['background/surface-inverse'],
  'toggle/color/toggle-border-default': semanticTokensDarkColor['border/primary'],
  'toggle/color/toggle-border-disabled': semanticTokensDarkColor['border/disabled'],
  'toggle/color/toggle-border-selected': semanticTokensDarkColor['background/surface-inverse'],
  'toggle/color/toggle-fg-default': semanticTokensDarkColor['text/primary'],
  'toggle/color/toggle-fg-disabled': semanticTokensDarkColor['foreground/disabled'],
  'toggle/color/toggle-fg-selected': semanticTokensDarkColor['foreground/surface-inverse'],
} as const;

export const componentTokensColor = {
  light: componentTokensLightColor,
  dark: componentTokensDarkColor,
} as const;

export const componentTokensSpace = {
  'carousel-stepper/space/space': coreTokensSpace['2'],
  'icon-button/space/lg': semanticTokensSpace.md,
  'icon-button/space/md': semanticTokensSpace.sm,
  'icon-button/space/xl': semanticTokensSpace.lg,
  'input/space/horizontal-padding-lg': semanticTokensSpace.lg,
  'input/space/horizontal-padding-md': semanticTokensSpace.md,
  'input/space/horizontal-padding-sm': semanticTokensSpace.sm,
  'input/space/horizontal-padding-xl': semanticTokensSpace.lg,
  'input/space/icon-gap': semanticTokensSpace.sm,
  'input/space/message-gap': semanticTokensSpace.xs,
  'input/space/vertical-padding-lg': semanticTokensSpace.md,
  'input/space/vertical-padding-md': semanticTokensSpace.sm,
  'input/space/vertical-padding-sm': semanticTokensSpace.xs,
  'input/space/vertical-padding-with-label-lg': semanticTokensSpace.sm,
  'input/space/vertical-padding-with-label-md': semanticTokensSpace.xs,
  'input/space/vertical-padding-with-label-sm': semanticTokensSpace.xxs,
  'input/space/vertical-padding-with-label-xl': semanticTokensSpace.sm,
  'input/space/vertical-padding-xl': semanticTokensSpace.lg,
  'multi-step-form/space/lg': semanticTokensSpace['8xl'],
  'multi-step-form/space/md': semanticTokensSpace['8xl'],
  'multi-step-form/space/sm': semanticTokensSpace['5xl'],
  'multi-step-form/space/xs': semanticTokensSpace.xl,
  'navigation/space/nav-icon-divider-gap': coreTokensSpace['1-5'],
  'navigation/space/nav-icon-gap': coreTokensSpace['3'],
  'navigation/space/nav-padding-horizontal': semanticTokensSpace.xl,
  'navigation/space/nav-padding-vertical': semanticTokensSpace.lg,
  'step-navigation/space/step-icon-divider-gap': coreTokensSpace['1-5'],
  'step-navigation/space/step-icon-gap': coreTokensSpace['3'],
  'step-navigation/space/step-padding-horizontal': semanticTokensSpace.xl,
  'step-navigation/space/step-padding-vertical': semanticTokensSpace.lg,
  'stepper-header/space/iconbutton-rhs': coreTokensSpace['2-5'],
  'tabs/space/container-horizontal-padding': semanticTokensSpace.xs,
  'tabs/space/container-vertical-padding': semanticTokensSpace.xs,
  'tabs/space/horizontal-padding-sm': semanticTokensSpace.sm,
  'tabs/space/icon-gap': semanticTokensSpace.xxs,
  'tabs/space/vertical-padding': semanticTokensSpace.md,
};

export const componentTokensSize = {
  'button/size/lg': semanticTokensSize['4xl'],
  'button/size/md': semanticTokensSize['2xl'],
  'button/size/sm': semanticTokensSize.lg,
  'button/size/xl': semanticTokensSize['5xl'],
  'doc-upload/size/display-height': coreTokensSpace['14'],
  'doc-upload/size/web-upload-height': coreTokensSpace['28'],
  'form/size/lg': semanticTokensSize['4xl'],
  'form/size/md': semanticTokensSize['2xl'],
  'form/size/sm': semanticTokensSize.lg,
  'form/size/xl': semanticTokensSize['5xl'],
  'icon-button/size/icon': coreTokensSize['1-5'],
  'icon-button/size/lg': semanticTokensSize['4xl'],
  'icon-button/size/md': semanticTokensSize['2xl'],
  'icon-button/size/xl': semanticTokensSize['5xl'],
  'icon/size/lg': semanticTokensSize.lg,
  'icon/size/md': semanticTokensSize.md,
  'icon/size/sm': semanticTokensSize.sm,
  'icon/size/xs': semanticTokensSize.xs,
  'input/size/border-focus-width': coreTokensSize['0-25'],
  'input/size/border-width': coreTokensSize['0-12-5'],
  'input/size/lg': semanticTokensSize['4xl'],
  'input/size/md': semanticTokensSize['2xl'],
  'input/size/sm': semanticTokensSize.lg,
  'input/size/xl': semanticTokensSize['5xl'],
  'multi-step-form/size/lg': coreTokensSize['50_percent'],
  'multi-step-form/size/md': coreTokensSize['62-5_percent'],
  'multi-step-form/size/sm': coreTokensSize['75_percent'],
  'multi-step-form/size/xs': coreTokensSize['100_percent'],
  'navigation/size/nav-horizontal-divider-width': coreTokensSize['0-12-5'],
  'navigation/size/nav-outline-width-focus': coreTokensSize['0-25'],
  'navigation/size/nav-vertical-divider-width': coreTokensSize['0-12-5'],
  'navigation/size/sidebar': coreTokensSize['20'],
  'navigation/size/sidebar-collapsed': coreTokensSize['7'],
  'navigation/size/sidebar-header': coreTokensSize['9'],
  'step-navigation/size/sidebar': coreTokensSize['20'],
  'step-navigation/size/step-divider-width': coreTokensSize['0-12-5'],
  'step-navigation/size/step-number-icon-border-width': coreTokensSize['0-25'],
  'step-navigation/size/step-outline-width-focus': coreTokensSize['0-25'],
  'switch/size/lg': semanticTokensSize.xl,
  'switch/size/md': semanticTokensSize.lg,
  'switch/size/sm': semanticTokensSize.sm,
};

export const componentTokensRadius = {
  'alert/radius/alert': semanticTokensRadius['2xl'],
  'badge/radius/badge': semanticTokensRadius.round,
  'button/radius/button-radius': semanticTokensRadius.round,
  'checkbox/radius/checkbox-radius': semanticTokensRadius.sm,
  'fab/radius/fab-radius': semanticTokensRadius.round,
  'form/radius/formcontrol': semanticTokensRadius.md,
  'surface/radius/surface-radius': semanticTokensRadius['2xl'],
  'tab/radius/tab-radius': semanticTokensRadius.round,
  'tabs/radius/container-radius': semanticTokensSpace.md,
  'tabs/radius/tab-radius': semanticTokensSpace.md,
  'toast/radius/toast': semanticTokensRadius.round,
  'toggle/radius/toggle-radius-multi': semanticTokensRadius['2xl'],
  'toggle/radius/toggle-radius-single': semanticTokensRadius.round,
};
