import type { ReactElement } from 'react';
import { Stack, YStack } from 'tamagui';
import branding from '@cxnpl/ui/brand';
import { X } from '@cxnpl/ui/icons';
import { Image } from '../Image';
import { IconButton } from '../IconButton';
import { FullScreenOverlayBase } from './FullScreenOverlayBase';

interface FullScreenOverlayProps {
  visible: boolean;
  children: ReactElement | null;
  onClose: () => void;
  disabled?: boolean;
  showLogo?: boolean;
}

export const FullScreenOverlay = ({
  visible,
  onClose,
  disabled,
  showLogo = false,
  children,
}: FullScreenOverlayProps) => {
  return (
    <FullScreenOverlayBase visible={visible} overlayProps={{ backgroundColor: '$background/surface' }}>
      <YStack
        padding="$space.2xl"
        paddingTop="$space.8xl"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {showLogo ? (
          <Stack position="absolute" top="$space.xl" left="$space.2xl" zIndex={1}>
            <Image
              src={branding.assets.logo.src}
              alt="brand logo"
              width={branding.assets.logo.width}
              height={branding.assets.logo.height}
              style={{ objectFit: 'contain' }}
              nativeProps={{ resizeMode: 'contain' }}
            />
          </Stack>
        ) : null}
        <IconButton
          icon={<X />}
          disabled={disabled}
          onPress={onClose}
          mode="secondary"
          variant="outlined"
          size="lg"
          testID="close-button"
          position="absolute"
          top="$space.xl"
          right="$space.2xl"
          zIndex={1}
        />
        {children}
      </YStack>
    </FullScreenOverlayBase>
  );
};
