import { XStack } from 'tamagui';
import type { ReactNode } from 'react';
import { Text, type TextProps } from '../Text';

interface BulletPointProps {
  color: TextProps['color'];
  variant: TextProps['variant'];
  children: string | ReactNode;
  testID?: string;
}
export const BulletPoint = ({ color, variant, children, testID }: BulletPointProps) => {
  return (
    <XStack gap="$space.sm">
      <Text color={color} variant={variant}>
        {'\u2022 '}
      </Text>
      {typeof children === 'string' ? (
        <Text color={color} variant={variant} testID={testID ? `${testID}-bullet-point` : 'bullet-point'}>
          {children}
        </Text>
      ) : (
        children
      )}
    </XStack>
  );
};
