import type { TabsTabProps } from 'tamagui';
import { Tabs, withStaticProperties } from 'tamagui';
import { match } from 'ts-pattern';
import type { TextProps } from '../Text';
import { Text } from '../Text';

function BaseTabs({
  value,
  onValueChange,
  children,
}: {
  value: string; // Selected tab
  onValueChange: (tab: string) => void; // Handles changing the selected tab
  children: React.ReactNode; // In this case, Tabs.List
}) {
  return (
    <Tabs
      value={value}
      onValueChange={onValueChange}
      orientation="horizontal"
      flexDirection="column"
      borderWidth={1}
      borderRadius="$button/radius/button-radius"
      borderColor="$border/surface-subdued"
      width="100%"
    >
      {/* Individual tabs held in TabList */}
      {children}
    </Tabs>
  );
}

// Tab group
function BaseTabList({ children }: { children: React.ReactNode }) {
  return <Tabs.List aria-label="Choose tab">{children}</Tabs.List>;
}

// Individual Tab items
function BaseTab({
  value,
  selected,
  children,
  ...rest
}: TabsTabProps & { selected?: boolean; children: React.ReactNode }) {
  const isDisabled = rest.disabled;

  return (
    <Tabs.Tab
      unstyled
      pointerEvents="box-only"
      flex={1}
      value={value}
      borderRadius="$button/radius/button-radius"
      cursor={match({ isDisabled })
        .with({ isDisabled: true }, () => 'not-allowed')
        .otherwise(() => 'pointer')}
      borderWidth={selected ? 1 : 0}
      borderColor={selected ? '$form/color/form-border-selected' : undefined}
      {...rest}
    >
      <Text
        pointerEvents="none"
        variant={selected || isDisabled ? 'bodyMedium' : 'linkMedium'}
        color={
          match({ selected, isDisabled })
            .with({ selected: true }, () => '$form/color/form-fg-selected')
            .with({ isDisabled: true }, () => '$text/disabled')
            .otherwise(() => '$button/color/button-primary-text') as TextProps['color']
        }
      >
        {children}
      </Text>
    </Tabs.Tab>
  );
}

// Renaming and bringing everything together
export const Tab = withStaticProperties(BaseTabs, {
  Group: BaseTabList,
  Item: BaseTab,
});
