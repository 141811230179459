import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';
import { Stack, XStack, YStack } from 'tamagui';
import { ChevronDown, X } from '@tamagui/lucide-icons';
import type { GestureResponderEvent } from 'react-native';
import { StepNavigation } from '../../StepNavigation';
import type { BaseStepComponentProps, SubStep } from '../types';
import { Text } from '../../Text';
import { FullScreenOverlayBase } from '../../FullScreenOverlay';
import { Elevation } from '../../Elevation';

// TODO PWA-1380: Generalize this component
const IconButtonWrapper = ({
  icon,
  onPress,
}: {
  icon: ReactElement;
  onPress?: (event: GestureResponderEvent) => void;
}) => {
  return (
    <Stack
      width="$icon-button/size/lg"
      height="$icon-button/size/lg"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onPress={onPress}
      cursor="pointer"
    >
      {icon}
    </Stack>
  );
};

interface MultiStepNavigationProps {
  compactGroupIfSingleChild: boolean;
  compactMode: boolean;
  currentStepId: string;
  currentStepIndex: number;
  enforceStepOrder: boolean;
  renderedSteps: {
    steps: SubStep<React.FC>[];
    id: string;
    name: string;
    hidden?: boolean;
  }[];
  stepIds: string[];
  stepsById: Record<
    string,
    SubStep<React.FC<BaseStepComponentProps>> & {
      groupId: string;
      groupName: string;
      stepIndex: number;
    }
  >;
  testID: string | undefined;
  changeStep: (newStepIndex: number) => void;
  onClose: (() => void) | undefined;
}

export const MultiStepFormNavigation = ({
  compactGroupIfSingleChild,
  compactMode,
  currentStepId,
  currentStepIndex,
  enforceStepOrder,
  renderedSteps,
  stepIds,
  stepsById,
  testID,
  onClose,
  changeStep,
}: MultiStepNavigationProps) => {
  const currentStep = stepsById[currentStepId];

  const [showOverlay, setShowOverlay] = useState(false);

  // Used to calculate progress. Progress is defined as completedSteps / totalSteps.
  const { totalSteps, completedSteps } = useMemo(() => {
    return renderedSteps.reduce(
      (acc, stepGroup) => {
        const groupsWithoutHidden = stepGroup.steps.filter((step) => !step.hidden);
        const groupTotal = groupsWithoutHidden.length;
        const groupCompleted = groupsWithoutHidden.filter((step) => step.completed).length;

        return {
          totalSteps: acc.totalSteps + groupTotal,
          completedSteps: acc.completedSteps + groupCompleted,
        };
      },
      { totalSteps: 0, completedSteps: 0 }
    );
  }, [renderedSteps]);

  const AccordionNavigation = useMemo(() => {
    let stepIndex = 0;

    return (
      <StepNavigation
        defaultOpen={[]}
        testID={testID ? `${testID}-step-navigation` : undefined}
        selected={currentStepId}
        mode={compactMode ? 'compact' : 'full'}
        collapsed={false}
        setCollapsed={() => {
          setShowOverlay(false);
        }}
      >
        {renderedSteps.map((stepGroup, stepGroupIndex) => {
          let allChildrenDisabledOrUnSelectable = true;

          const StepNavigationItems = (() =>
            stepGroup.steps.map((step) => {
              // stepIndex, currentIndex and currentStepIndex will be referring to an index in orderedStepsById
              const currentIndex = stepIndex;
              stepIndex += 1;

              const selected = currentIndex === currentStepIndex;
              const disabled = !!step.disabled;
              const completed = !!step.completed;

              // Check all previous, not hidden steps if they're completed
              // Just checking one previous step is not enough, as the form data may have been directly changed by a service agent.
              const allPreviousStepsCompleted = (() => {
                let previousStepIndex = currentIndex - 1;
                let allPreviousStepsComplete = true;
                while (previousStepIndex >= 0) {
                  const previousStepId = stepIds[previousStepIndex] ?? '';
                  const previousStep = stepsById[previousStepId];
                  if (previousStep && !previousStep.completed) {
                    allPreviousStepsComplete = false;
                    break;
                  }
                  previousStepIndex -= 1;
                }
                return allPreviousStepsComplete;
              })();

              // Navigation item is selectable when:
              // If enforceStepOrder is true - if it's the first step, or all previous steps have been completed
              // If enforceStepOrder is false - then it's always selectable
              let selectable = false;
              if (enforceStepOrder) {
                if (currentIndex === 0) {
                  selectable = true;
                } else {
                  selectable = allPreviousStepsCompleted;
                }
              } else {
                selectable = true;
              }

              // Finding the first selectable index and unflagging if any child is not disabled or selectable
              if (!disabled && selectable) {
                allChildrenDisabledOrUnSelectable = false;
              }

              return (
                <StepNavigation.Item
                  testID={testID ? `${testID}-${step.id}-step-navigation-item-button` : undefined}
                  key={`${stepIndex}_${step.id}`}
                  id={step.id}
                  label={step.name}
                  completed={completed}
                  disabled={disabled || !selectable}
                  onPress={() => {
                    // If the step is disabled, not selectable, or already selected, don't navigate to it
                    if (disabled || !selectable || selected) {
                      return;
                    }
                    changeStep(currentIndex);
                  }}
                />
              );
            }))();

          return stepGroup.steps.length === 1 && compactGroupIfSingleChild ? (
            StepNavigationItems
          ) : (
            <StepNavigation.Group
              testID={testID ? `${testID}-${stepGroup.id}-step-navigation-group-button` : undefined}
              key={`${stepGroupIndex}_${stepGroup.id}`}
              id={stepGroup.id}
              label={stepGroup.name}
              // Disabled if all children are also disabled or un-selectable
              disabled={allChildrenDisabledOrUnSelectable}
            >
              {StepNavigationItems}
            </StepNavigation.Group>
          );
        })}
      </StepNavigation>
    );
  }, [
    renderedSteps,
    currentStepId,
    currentStepIndex,
    compactMode,
    enforceStepOrder,
    stepIds,
    stepsById,
    testID,
    changeStep,
    compactGroupIfSingleChild,
  ]);

  // If were not in compact mode just return the regular accordion navigation
  if (!compactMode) {
    return AccordionNavigation;
  }

  // If were in compact mode return the custom top button and overlay menu
  return (
    <>
      {/* Step Navigation Menu Button */}
      <YStack>
        <XStack
          id="step-navigation-menu-wrapper"
          alignItems="center"
          paddingRight={10} // TODO PWA-1380: remove hardcoded value
          backgroundColor="$background/surface"
        >
          <XStack
            id="step-navigation-menu-button"
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            paddingLeft="$space.xl"
            paddingRight="$space.lg"
            paddingVertical="$space.sm"
            onPress={() => {
              setShowOverlay(true);
            }}
            cursor="pointer"
            gap="$space.md"
          >
            <YStack flex={1}>
              <Text variant="bodyMediumEm">{currentStep?.groupName}</Text>
              {!compactGroupIfSingleChild && <Text variant="bodySmall">{currentStep?.name}</Text>}
            </YStack>
            <ChevronDown />
          </XStack>
          <Stack height={32} borderLeftWidth={1} borderLeftColor="$border/surface-subdued" />
          <XStack id="step-navigation-menu-icon-buttons">
            {/* // TODO PWA-1380: Add back the chat function from here */}
            {/* <IconButtonWrapper icon={<HelpCircle size="$xs" />}  /> */}
            <IconButtonWrapper icon={<X size="$xs" />} onPress={onClose} />
          </XStack>
        </XStack>
        {/* Step Progress Bar */}
        <XStack
          id="step-navigation-progress-bar"
          backgroundColor="$border/surface-subdued"
          borderBottomWidth={4} // TODO PWA-1380: remove hardcoded value
          borderColor="$border/surface-subdued"
        >
          <Stack
            height={4} // TODO PWA-1380: remove hardcoded value
            transform={[{ scaleX: completedSteps / totalSteps }]}
            width="100%"
            animation="250ms"
            backgroundColor="$icon/primary"
            transformOrigin="left"
          />
        </XStack>
      </YStack>

      {/* Step navigation accordion accessible as overlay */}
      <FullScreenOverlayBase
        visible={showOverlay}
        overlayProps={{
          backgroundColor: '$step-navigation/color/step-bg-default',
        }}
        scrollViewProps={{
          stickyHeaderIndices: [0],
        }}
      >
        {/* Top bar in full screen overlay */}
        {/* https://stackoverflow.com/questions/54751815/how-can-i-set-elevation-shadow-only-on-the-bottom-on-react-native */}
        <YStack overflow="hidden" paddingBottom={8}>
          <Elevation
            flexDirection="row"
            gap="$space.lg"
            paddingLeft="$space.lg"
            paddingRight={10}
            alignItems="center"
            justifyContent="space-between"
            backgroundColor="$step-navigation/color/step-bg-default"
            shadow="soft"
            level={1}
          >
            {/* TODO PWA-1380: Replace that with the overall form name */}
            <Text marginLeft="$space.sm" variant="bodyMediumEm" color="$step-navigation/color/step-fg-default">
              Your application
            </Text>
            <IconButtonWrapper
              icon={<X size="$size.xs" color="$step-navigation/color/step-fg-default" />}
              onPress={() => {
                setShowOverlay(false);
              }}
            />
          </Elevation>
        </YStack>
        {AccordionNavigation}
      </FullScreenOverlayBase>
    </>
  );
};
