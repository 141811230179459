import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M4.625 4.09735C4.6907 4.05346 4.82302 4 5.00962 4H20.2404C20.427 4 20.5593 4.05346 20.625 4.09735V8.90265C20.5593 8.94654 20.427 9 20.2404 9H5.00962C4.82302 9 4.6907 8.94654 4.625 8.90265V4.09735ZM20.66 4.12518C20.66 4.12519 20.6596 4.12478 20.6589 4.12396L20.66 4.12518ZM3.44505 2.48221C3.88405 2.16516 4.44043 2 5.00962 2H20.2404C20.8096 2 21.3659 2.16516 21.8049 2.48221C22.2426 2.79831 22.625 3.32201 22.625 4V9C22.625 9.67799 22.2426 10.2017 21.8049 10.5178C21.3659 10.8348 20.8096 11 20.2404 11H5.00962C4.44043 11 3.88405 10.8348 3.44505 10.5178C3.00738 10.2017 2.625 9.67799 2.625 9V4C2.625 3.32201 3.00738 2.79831 3.44505 2.48221Z"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M4.625 15.0974C4.6907 15.0535 4.82302 15 5.00962 15H20.2404C20.427 15 20.5593 15.0535 20.625 15.0974V19.9026C20.5593 19.9465 20.427 20 20.2404 20H5.00962C4.82302 20 4.6907 19.9465 4.625 19.9026V15.0974ZM20.66 15.1252C20.66 15.1252 20.6596 15.1248 20.6589 15.124L20.66 15.1252ZM3.44505 13.4822C3.88405 13.1652 4.44043 13 5.00962 13H20.2404C20.8096 13 21.3659 13.1652 21.8049 13.4822C22.2426 13.7983 22.625 14.322 22.625 15V20C22.625 20.678 22.2426 21.2017 21.8049 21.5178C21.3659 21.8348 20.8096 22 20.2404 22H5.00962C4.44043 22 3.88405 21.8348 3.44505 21.5178C3.00738 21.2017 2.625 20.678 2.625 20V15C2.625 14.322 3.00738 13.7983 3.44505 13.4822Z"
      />
    </Svg>
  );
};

Icon.displayName = 'Accounts';

export const Accounts = memo<IconProps>(themed(Icon));
