'use client';

/* eslint-disable @typescript-eslint/no-unsafe-argument -- from Tamagui implementation */
/* eslint-disable @typescript-eslint/no-non-null-assertion -- From Tamagui implementation */
import { FloatingFocusManager } from '@floating-ui/react';
import { AnimatePresence } from '@tamagui/animate-presence';
import { composeRefs } from '@tamagui/compose-refs';
import { isWeb, useIsomorphicLayoutEffect } from '@tamagui/constants';
import type { TamaguiComponent } from '@tamagui/core';
import { styled } from '@tamagui/core';
import { PortalItem } from '@tamagui/portal';
import type { ThemeableStackProps } from '@tamagui/stacks';
import { ThemeableStack } from '@tamagui/stacks';
import { useEffect } from 'react';
import { VIEWPORT_NAME } from './constants';
import { ForwardSelectContext, useSelectContext, useSelectItemParentContext } from './context';
import type { ScopedProps, SelectViewportExtraProps, SelectViewportProps } from './types';
import { useSelectBreakpointActive } from './useSelectBreakpointActive';

// box shadow added here, as wrapping the SelectViewportFrame in an Elevation did not work
const selectViewportCSS = `
.is_SelectViewport {
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
}

.is_SelectViewport::-webkit-scrollbar {
  display: none;
}
`;

/* -------------------------------------------------------------------------------------------------
 * SelectViewport
 * ## The frame containing all the items and the title item ##
 * -----------------------------------------------------------------------------------------------*/

export const SelectViewportFrame: TamaguiComponent<ThemeableStackProps> = styled(ThemeableStack, {
  name: VIEWPORT_NAME,
  elevate: true,
  bordered: true,
  userSelect: 'none',
  outlineWidth: 0,
  borderColor: '$form/color/form-border-default',
  borderRadius: '$form/radius/formcontrol',

  variants: {
    unstyled: {
      false: {},
    },

    size: {
      '...size': () => {
        return {};
      },
    },
  } as const,

  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === '1',
  },
});

export const SelectViewport: TamaguiComponent<SelectViewportProps> =
  SelectViewportFrame.styleable<SelectViewportExtraProps>(function SelectViewport(
    props: ScopedProps<SelectViewportProps>,
    forwardedRef
  ) {
    const { __scopeSelect, children, disableScroll, ...viewportProps } = props;
    const context = useSelectContext(VIEWPORT_NAME, __scopeSelect);
    const itemContext = useSelectItemParentContext(VIEWPORT_NAME, __scopeSelect);
    const breakpointActive = useSelectBreakpointActive(context.sheetBreakpoint);

    // Remove set inner html
    useEffect(() => {
      // Only inject styles if conditions are met
      if (!disableScroll && !props.unstyled) {
        const style = document.createElement('style');
        style.innerHTML = selectViewportCSS;
        document.head.appendChild(style);

        // Cleanup the style tag when component unmounts or conditions change
        return () => {
          document.head.removeChild(style);
        };
      }
      return undefined;
    }, [disableScroll, props.unstyled]); // Run effect when these props change

    useIsomorphicLayoutEffect(() => {
      if (context.update) {
        context.update();
      }
    }, [breakpointActive]);

    if (itemContext.shouldRenderWebNative) {
      return <>{children}</>;
    }

    if (breakpointActive || !isWeb) {
      return (
        <PortalItem hostName={`${context.scopeKey}SheetContents`}>
          <ForwardSelectContext __scopeSelect={__scopeSelect} itemContext={itemContext} context={context}>
            {children}
          </ForwardSelectContext>
        </PortalItem>
      );
    }

    if (!itemContext.interactions) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console -- from Tamagui implementation
        console.warn(`No interactions provided to Select, potentially missing Adapt`);
      }

      return null;
    }

    const {
      style,
      // remove this, it was set to "Select" always
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- from Tamagui implementation, probably trying to remove className from floatingProps
      className,
      ...floatingProps
    } = itemContext.interactions.getFloatingProps();

    const composedRefs = composeRefs(
      forwardedRef,

      context.floatingContext?.refs.setFloating
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- from Tamagui implementation, probably trying to remove those props from restStyle
    const { scrollbarWidth, listStyleType, overflow, ...restStyle } = style;

    return (
      <>
        <AnimatePresence>
          {context.open ? (
            <FloatingFocusManager context={context.floatingContext!} modal={false}>
              <SelectViewportFrame
                disableClassName
                key="select-viewport"
                size={itemContext.size}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error -- From Tamagui implementation
                // @ts-ignore
                role="presentation"
                {...viewportProps}
                {...floatingProps}
                {...restStyle}
                {...(!props.unstyled && {
                  overflow: disableScroll ? undefined : overflow ?? 'scroll',
                })}
                ref={composedRefs}
              >
                {children}
              </SelectViewportFrame>
            </FloatingFocusManager>
          ) : null}
        </AnimatePresence>

        {/* keep in dom to allow for portal to the trigger... very hacky! we should fix */}
        {!context.open && <div style={{ display: 'none' }}>{props.children}</div>}
      </>
    );
  });
