import { Svg, Path } from 'react-native-svg';
import type { IconProps } from '@tamagui/helpers-icon';
import { themed } from '@tamagui/helpers-icon';
import { memo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from tamagui/lucide-icons
const Icon = (props: any) => {
  const { color = 'black', size = 24, ...otherProps } = props;
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M9.25592 3.91083C9.58136 4.23626 9.58136 4.7639 9.25592 5.08934L6.51184 7.83342L9.25592 10.5775C9.58136 10.9029 9.58136 11.4306 9.25592 11.756C8.93049 12.0814 8.40285 12.0814 8.07741 11.756L4.74408 8.42267C4.41864 8.09723 4.41864 7.5696 4.74408 7.24416L8.07741 3.91083C8.40285 3.58539 8.93049 3.58539 9.25592 3.91083Z"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M4.5 7.83342C4.5 7.37318 4.8731 7.00008 5.33333 7.00008H18.6667C19.1269 7.00008 19.5 7.37318 19.5 7.83342C19.5 8.29365 19.1269 8.66675 18.6667 8.66675H5.33333C4.8731 8.66675 4.5 8.29365 4.5 7.83342Z"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M14.7441 12.2442C15.0695 11.9187 15.5972 11.9187 15.9226 12.2442L19.2559 15.5775C19.5814 15.9029 19.5814 16.4306 19.2559 16.756L15.9226 20.0893C15.5972 20.4148 15.0695 20.4148 14.7441 20.0893C14.4186 19.7639 14.4186 19.2363 14.7441 18.9108L17.4882 16.1667L14.7441 13.4227C14.4186 13.0972 14.4186 12.5696 14.7441 12.2442Z"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M4.5 16.1667C4.5 15.7065 4.8731 15.3334 5.33333 15.3334H18.6667C19.1269 15.3334 19.5 15.7065 19.5 16.1667C19.5 16.627 19.1269 17.0001 18.6667 17.0001H5.33333C4.8731 17.0001 4.5 16.627 4.5 16.1667Z"
      />
    </Svg>
  );
};
Icon.displayName = 'Swap';

export const Swap = memo<IconProps>(themed(Icon));
